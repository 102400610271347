.section-1 {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;

  h1 {
    color: $lightgreen;
    display: flex;
    flex-wrap: wrap;
    max-width: 780px;
    width: 100%;
    padding-left: 70px;
    position: relative;


    div {
      //overflow: hidden;
    }


    span {
      display: block;
      margin-right: 20px;
    }
  }

  & .curves {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;

    & .arrow {
      position: absolute;
      left: 1%;
      top: 50%;
      transform: translateY(-50%);
      height: 130%;
      transform-origin: center;
    }

    & .curve-bottom {
      position: absolute;
      bottom: -55px;
      right: -157px;
      width: 50%;
    }

    & .curve-top {
      position: absolute;
      top: -55px;
      right: -95px;
      width: 45%;
    }
  }



}

@keyframes breathing {
  0% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }

  25% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }

  60% {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
  }

  100% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
}


@media screen and(max-width: 1600px){
  .section-1 {
    & .curves {
      & .arrow {
        left: -10%
      }
    }
  }


}

@media screen and(max-width: 1350px){
  .section-1 {
    & .curves {
      & .arrow {
        left: -15%
      }
    }
  }


}

@media screen and(max-width: 1250px){
  .section-1 {
    & .curves {
      & .arrow {
        left: -20%
      }
    }
  }


}

@media screen and(max-width: 1100px){
  .section-1 {
    & .curves {
      & .arrow {
        left: -15%;
        height: 90%
      }

      & .curve-bottom {
        position: absolute;
        bottom: -55px;
        right: -100px;
        width: 50%;
      }

      & .curve-top {
        position: absolute;
        top: -55px;
        right: -65px;
        width: 45%;
      }
    }


  }


}


@media screen and(max-width: 900px){
  .section-1 {

    & h1 {
      justify-content: center;
      padding-left: 0;
    }
    & .curves {
      & .arrow {
        left: -15%;
        height: 60%
      }

      & .curve-bottom {
        position: absolute;
        bottom: -55px;
        right: -100px;
        width: 60%;
      }

      & .curve-top {
        position: absolute;
        top: -55px;
        right: -65px;
        width: 55%;
      }
    }
  }


}

@media screen and(max-width: 700px){
  .section-1 {
    padding: 1em;

    & h1 {
      justify-content: center;

    }
    & .curves {
      & .arrow {
        left: -15%;
        height: 60%
      }

      & .curve-bottom {
        position: absolute;
        bottom: -15px;
        right: -100px;
        width: 70%;
      }

      & .curve-top {
        position: absolute;
        top: -15px;
        right: -65px;
        width: 60%;
      }
    }
  }


}


@media screen and(max-width: 500px){
  .section-1 {
    padding: 1em;

    & h1 {
      justify-content: center;
      padding-left: 20px;
    }
    & .curves {
      & .arrow {
        left: -23%;
        height: 50%
      }
    }
  }


}