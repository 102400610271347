* {
  box-sizing: border-box;
  margin: 0;
}

html {
  //font-family: 'Century Gothic', sans-serif;
  //font-family: 'Lato', sans-serif;
  font-family: 'Mulish', sans-serif;

}

body {
  margin: 0;
  font-size: 18px;
  color: #131e22;
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
}

body::-webkit-scrollbar { /* WebKit */
  width: 0;
  height: 0;
}

p {
  line-height: 1.5;
}

h1, h2, h3, h4, h5, h6 {
  //font-family: 'Century Gothic', sans-serif;
  //font-family: 'Lato', sans-serif;
  font-family: 'Mulish', sans-serif;
  font-weight: 900;
}

h1 {
  font-size: 7em;
  font-weight: bold;
  text-transform: lowercase;
}

h2 {
  font-size: 7em;
  font-weight: bold;
  text-transform: lowercase;
}

h3 {
  font-size: 3em;
}

.subtitle {
  font-weight: bold;
  font-size: 1.5em;
}

h4 {
  font-size: 2.5em;
}

h5 {
  font-size: 1.4em;
}


.elevens {
  position: relative;
  //margin-bottom: 55vh;
  &__background {
    position: fixed;
    top: 0;
    z-index: 70;
    width: 100%;
    height: 100%;
    transition: 1.5s ease-in-out;
    background-color: $green;

    &.green {
      background-color: $green;
    }

    &.white {
      background-color: #ffffff;
    }

    &.blue {
      background-color: $blue;
    }
    &.lightblue {
      background-color: $lightblue;
    }

    &.red {
      background-color: $red;
    }

    &.delay {
      transition-delay: 1s !important;
    }
    &.no-transition {
      transition-duration: 0ms !important;
    }

    &.nodelay {
      transition-delay: 0s !important;
      transition: 0s!important;
    }
  }
}

.fixed-logo {
  position: fixed;
  top: 2em;
  left: 2em;
  cursor: pointer;
  &:hover {
    svg {
      width: 70px;
    }
  }
  z-index: 90;

  svg {
    width: 60px ;
    transition: width .15s ease-in-out;
  }
}


.heading-l {
  display: flex;
  flex-wrap: wrap;
  font-weight: bold;
  font-size: 6.5em;

  span {
    margin-right: 15px;
  }
}

.linkedin-icon {
  opacity: .7;
  width: 30px!important;
  display: block;
  margin-bottom: 10px;
}

.full-height {
  width: 100%;
  height: 100%;
  z-index: 79;
}

.no-scroll {
  height: 100%;
  overflow: hidden;
}

.fixed {
  position: fixed;
  top: 50%;
  background-color: green;
  color: #fff;
  z-index: 100;
}

.navbuttons {
  position: fixed;
  right: 0;
  top: 50%;
  z-index: 10000000;
  color: #000000;
  & .prev {
    background-color: $green;
    padding: 10px 15px;
    color: #fff;
    margin-bottom: 5px;

  }
  & .next {
    background-color: $green;
    color: #fff;
    padding: 10px 15px;
  }
}



.scroller {
  position: fixed;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 90;
  color: $red;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  font-size: 14px;

  &__inner {
    position: relative;
    margin-bottom: 10px;
    overflow: hidden;
    height: 50px;
    width: 100%;

    &:after {
      position: absolute;
      top: 0;
      content: '';
      left: calc(50% - 1px);
      width: 1px;
      height: 50px;
      background-color: $red;
      animation: scroller 2s ease-in-out infinite;
    }
  }
}

.active-section {
  z-index: 80!important;
}

.active-section-overflow {
  z-index: 82!important;
}

.sections-overflow-elements {
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 78 ;

  &.background-active {
    z-index: 81;
  }



  & .white-background {
    position: fixed;
    width: 100%;
    height: 100%;
    // background-color: #fff;
  }

  & .arrow {
    position: fixed;
    left: -40px;
    top: 0;
    height: 100%;
    z-index: 82;
  }


  & .red-background {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
  }

  & .man {
    position: absolute;
    width: 100%;
    height: 100%;

    //bottom: -5px;
    //overflow: hidden;

    img {
      position: absolute;
      &:nth-of-type(1) {
        z-index: 3;
        left: -100px;
        bottom: 0;
        max-width: 940px;
        width: 100%;
      }

      &:nth-of-type(2) {
        z-index: 2;
        left: 400px;
        bottom: 0;
        max-width: 550px;
        width: 100%;
        opacity: 0.8;
      }

      &:nth-of-type(3) {
        z-index: 1;
        opacity: 0.6;
        left: 750px;
        bottom: 0;
        max-width: 350px;
        width: 100%;
      }
    }
  }
}

.no-site {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding: 2em;
  background-color: $green;

  & h3 {
    color: $lightgreen;
    margin-bottom: 20px;
  }

  p {
    color: $lightgreen;
  }
}

.hidden {
  opacity: 1;
}

.logo-i {
  transition: 1s ease;
}

@keyframes scroller {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(100%);
  }

}


@media screen and(max-width: 800px){
  body {
    font-size: 16px
  }

  h1 {
    font-size: 3.5em;
  }

  h2 {
    font-size: 3.5em;
  }

  h3 {
    font-size: 1.7em;
  }

  h4 {
    font-size: 1.4em;
  }

  .subtitle {
    font-size: 1em;
  }
}

@media screen and(max-width: 970px){
  //body {
  //  font-size: 80%;
  //}


  .full-height {
    width: 100%;
    min-height: 100%;
    height: 100%;
    position: relative;
  }
}
