.section-6 {
  display: flex;
  align-items: center;
  justify-content: center;

  position: fixed;
  top: 0;

  h3 {
    position: relative;
    z-index: 3;
    overflow: hidden;
    display: flex;

    div {
      margin-right: 13px;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  & .curves {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;


    & .curve-top {
      position: absolute;
      top: -100px;
      left: 50%;
      transform: translateX(-50%);
      width: 850px;
    }
    & .curve-bottom {
      position: absolute;
      bottom: -100px;
      left: 50%;
      transform: translateX(-50%);
      width: 850px;
    }


  }
}

@media screen and(max-width: 1200px){

  .section-6 {

    & .curves {

      & .arrow {
        position: absolute;
        left: -60px;
        top: 50%;
        transform: translateY(-50%);
        width: 200px;
      }

      & .curve-top {
        top: -100px;
        width: 650px;
      }
      & .curve-bottom {
        bottom: -100px;
        width: 650px;
      }
    }

  }
}


@media screen and(max-width: 970px){
  .section-6 {
    & .curves {

      & .arrow {

        width: 150px;
      }

      & .curve-top {
        top: -130px;
        width: 650px;
      }
      & .curve-bottom {
        bottom: -130px;
        width: 650px;
      }
    }

  }
}

@media screen and(max-width: 500px){
  .section-6 {
    padding: 2em;

    h3 {
      justify-content: center;
      flex-wrap: wrap;
      text-align: center;
    }
    & .curves {

      & .arrow {
        left: -30px;
        width: 130px;
      }

      & .curve-top {
        top: -55px;
        width: 450px;
      }
      & .curve-bottom {
        bottom: -55px;
        width: 450px;
      }
    }
  }
}

@media screen and(max-width: 350px){
  .section-6 {
    & .curves {
      & .arrow {
        display: none;
      }

    }
  }
}


