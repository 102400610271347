/* base */
@import './fonts';
@import './variables';
@import './layout';
@import './menu';

/*  Blocks */
@import './sections/section-1';
@import './sections/section-2';
@import './sections/section-3';
@import './sections/section-4';
@import './sections/section-5';
@import './sections/section-6';
@import './sections/section-7';
@import './principles';
@import './people';
@import './sections/section-10';
@import './sections/section-11';
@import './sections/section-12-footer';


/* animations */
@import './responsive';

.device-not-supported-view {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    background: rgb(9,19,20);
    background: linear-gradient(0deg, rgba(9,19,20,1) 0%, rgba(25,40,46,1) 100%);
    color: white;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2em;
    &__inner {
        text-align: center;

        img {
            max-width: 80px ;
            width: 100%;
            margin-bottom: 10px;
        }

        h3 {
            margin-bottom: 10px;
        }

        p {
            font-size: 14px;
        }

        & .logo {
            max-width: 200px;
            margin-top: 20px;
        }

    }
    &.hidden {
        pointer-events: none;
        opacity: 0;
        visibility: hidden;
        display: none;
    }

    .really-small-screen-inner {
        display: block;
        &.hidden {
            display: none;
        }
    }

    .landscape-view-inner {
        display: block;
        &.hidden {
            display: none;
        }
    }
}

