.section-4 {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-right: 10em ;
  overflow: hidden;
  position: fixed;
  top: 0;

  &.no-back {
    background-color: transparent;
  }


  &__inner {
    align-self: center;
    text-align: right;

    svg {
      width: 250px;
      margin-bottom: 25px;
    }

    h3 {
      display: flex;
      flex-direction: column;

      span {

      }

    }

  }


}

@media screen and(max-width: 1100px){
  .section-4 {
    padding-right: 5em ;

    & .man {
      img {
        position: absolute;
        &:nth-of-type(1) {
          left: -200px;
        }

        &:nth-of-type(2) {
          left: 300px;
        }

        &:nth-of-type(3) {
          left: 650px;
        }
      }
    }
  }
}


@media screen and(max-width: 900px){
  .section-4 {


    & .man {
      img {
        position: absolute;
        &:nth-of-type(1) {
          left: -300px;
        }

        &:nth-of-type(2) {
          left: 100px;
        }

        &:nth-of-type(3) {
          display: none;
          left: 650px;
        }
      }
    }
  }
}

@media screen and(max-width: 760px){
  .section-4 {
    padding: 2em ;

    &__inner {
      svg {
        width: 150px;
      }


    }

    & .man {
      img {
        position: absolute;
        &:nth-of-type(1) {
          left: -80px;
          max-width: 650px;
          opacity: 0.7;
        }

        &:nth-of-type(2) {
          left: 180px;
          max-width: 300px;
          opacity: 0.5;
        }

      }
    }
  }
}


