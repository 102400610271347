
.section-7 {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;


  h3 {
    position: relative;
    z-index: 3;
    div {
      overflow: hidden;

      span {
        transition: .3s ease;

        &.blush {
          animation: blush 2s ease-in-out;
          animation-delay: .2s;
        }
      }
    }
  }

  & .curves {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;

    & .arrow {
      position: absolute;
      transform-origin: center;
      left: 0;
      top: 0;
      height: 100%;
    }

    & .curve-top {
      position: absolute;
      right: -240px;
      top: -160px;
      width: 700px;
    }

    & .curve-bottom {
      position: absolute;
      right: -240px;
      bottom: -160px;
      width: 700px;
    }
  }
}

@keyframes blush {
  25%, 75% {
    color: $red;
  }  100% {
       color: #131e22;
     }
}

@media screen and(max-width: 1250px){

  .section-7 {

    & .curves {
      & .arrow {
        left: -100px;

      }

      & .curve-top {
        right: -80px;
        top: -100px;
        width: 500px;
      }

      & .curve-bottom {
        right: -80px;
        bottom: -100px;
        width: 500px;
      }
    }



  }
}

@media screen and(max-width: 1050px){
  .section-7 {
    & .curves {
      & .arrow {
        left: -200px;
      }
    }
  }
}



@media screen and(max-width: 970px){
  .section-7 {
    & .curves {
      & .arrow {
        left: -340px;

      }
    }

  }
}

@media screen and(max-width: 500px){
  .section-7 {

    & .curves {
      & .curve-top {
        right: -10px;
        top: -40px;
        width: 300px;
      }

      & .curve-bottom {
        right: -10px;
        bottom: -40px;
        width: 300px;
      }
    }

  }
}




