.menu {
  position: fixed;
  z-index: 100;
  top:0;
  left: 0;
  width: 82vw;
  height: 100%;
  background-color: $menugreen;
  padding: 2em;
  opacity: 1;
  transform: translateX(100%);

  &__side-click {
    position: fixed;
    top: 0;
    right: 0;
    transform: translateX(100%);
    background-color: transparent;
    height: 100%;
    width: 18vw;
  }

  &__heading {
    display: flex;
    align-items: center;
    margin-bottom: 80px;
    position: relative;
    //margin-left: 90px;

    svg {
      min-width: 60px;
      margin-right: 30px;
      width: 60px;
      cursor: pointer;
    }


    span {
      color: #fff;
      text-transform: uppercase;
      font-size: 1.7em;
      letter-spacing: 6px;
      white-space: nowrap;
      position: relative;
      font-weight: bold;
    }

    & .line {
      height: 1px;
      background-color: $grey;
      width: 0%;
      top: 50%;
      right: 0;
      margin-left: 45px;
      margin-right: 20px;
      position: relative;
      overflow: hidden;

      transition: .6s ease-out;
      transition-delay: .8s;
      &:after {
        content: '';
        position: absolute;
        top: 0;
        z-index: 2;
        height: 5px;
        left: 0;
        width: 0%;
        background-color: $red;
        transition: .3s ease-in-out;
        transition-delay: .9s;

      }

      &.show {
        width: 100%;

        &:after {
          width: 38%;
        }
      }
    }

  }

  &__nav {
    margin-left: 90px;
    height: 80%;
    position: relative;
    display:flex;
    flex-direction: column;
    justify-content: space-between;

    ul {
      list-style-type: none;
      padding-left: 0;

      li {
        margin-bottom: 55px;
        font-weight: bold;
        text-transform: uppercase;
        text-decoration: none;
        color: $grey;
        cursor: pointer;
        font-size: 1.5em;
        transition: .4s ease;


        &:hover {
          color: #fff;
        }

      }
    }

    & .heading-l {
      width: 630px;
      color: #223237;
      line-height: 1.1;
    }


    footer {
      position: absolute;
      right: 0;
      bottom: 20px;
      margin-right: 20px;
      color: $grey;
      font-size: 15px;
      text-align: right;
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      & .linkedin {
        margin-bottom: 20px;
      }
    }

    & .heading-l {

      & div {
        overflow: hidden;
      }

      & span {
        display: block;
        transform: translateY(120px);
        transition: .4s ease;
        transition-delay: .3s;

      }

      &.show {
        & span {
          transform: translateY(0);

        }
      }
    }

  }

  & .btn-close {
    position: absolute;
    top: 50%;
    margin-right: 70px;
    transform: translateY(-50%);
    right: 0;
    color: $grey;
    padding-right: 50px;
    cursor: pointer;

    &:hover {

      &:after {
        transform: rotate(360deg);
      }
      &:before {
        transform: rotate(360deg);
      }
    }

    &:after {
      content: '';
      position: absolute;
      top: 50%;
      right: 0;
      width: 30px;
      height: 1px;

      transform: rotate(45deg) translateY(-50%);
      transform-origin: center;
      background-color: $grey;
      transition: 1s ease;

    }

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      right: 0;
      width: 30px;
      height: 1px;
      transform: rotate(-45deg) translateY(-50%);
      background-color: $grey;
      transition: 1s ease;
    }

  }

  & .btn-close-mobile {
    position: absolute;
    top: 45px;
    margin-right: 40px;
    transform: translateY(-50%);
    right: 0;
    color: $grey;
    padding-right: 50px;
    cursor: pointer;
    display: none;


    &:after {
      content: '';
      position: absolute;
      top: 50%;
      right: 0;
      width: 20px;
      height: 1px;

      transform: rotate(45deg) translateY(-50%);
      transform-origin: center;
      background-color: $grey;
      transition: 1s ease;
      display: block;

    }

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      right: 0;
      width: 20px;
      height: 1px;
      transform: rotate(-45deg) translateY(-50%);
      background-color: $grey;
      transition: 1s ease;
      display: block;
    }

  }

}

.menu-btn {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  left: calc(2em - 20px);
  color: #fff;
  padding-left: 0 ;
  border-radius: 20px;
  z-index: 90;
  cursor: pointer;
  opacity: 0.5;
  transition: .4s ease;
  padding: 20px;
  padding-right: 60px;
  &.active {
    opacity: 1;
  }

  &.red {
    color: $red;

    &:before {
      background-color: $red;
    }
    &:after {
      background-color: $red;
    }

    &:hover {
      &:before {
        background-color: $green;
      }
      &:after {
        background-color: $green;
      }
    }
  }

  &.black {
    color: #000;

    &:before {
      background-color: #000;
    }
    &:after {
      background-color: #000;
    }

    &:hover {
      &:before {
        background-color: #000;
      }
      &:after {
        background-color: #000;
      }
    }
  }

  &:hover {
    opacity: 1;

    &:after {
      transform: translateY(-50%) translateX(10px) rotate(-45deg);
    }
    &:before {
      transform: translateY(-50%) translateX(10px) rotate(45deg);
    }
  }

  &:after {
    content: '';
    position: absolute;
    transform-origin: left top;
    right: 30px;
    height: 18px;
    width: 1px;
    top: 28px;
    transform: translateY(-50%) translateX(0) rotate(-45deg);
    background-color: #fff;
    transition: .4s ease;


  }
  &:before {
    content: '';
    transform-origin: bottom left;
    position: absolute;
    right: 30px;
    height: 18px;
    width: 1px;
    top: 35px;
    transform: translateY(-50%) translateX(0) rotate(45deg);
    background-color: #fff;
    transition: .4s ease;

  }
}

.menu-btn-mobile {
  position: fixed;
  top: 2em;
  right: 2em;
  color: #fff;
  padding-left: 1em ;
  z-index: 95;
  cursor: pointer;
  opacity: 0.5;
  font-size: 16px;
  visibility: hidden;

  &.black {
    color: #000;
  }

  &:hover {
    opacity: 1;

    &:after {
      transform: translateY(-50%) translateX(10px) rotate(-45deg);
    }
    &:before {
      transform: translateY(-50%) translateX(10px) rotate(45deg);
    }
  }
}


@media screen and(max-width: 1100px){
  .menu {
    width: 100%;
    padding: 30px;

    & .btn-close {
      display: none;
    }

    & .btn-close-mobile {
      display: block;
      width: 20px;
      height: 20px;
    }



    &__nav {
      margin-left: 0;
      height: 90%;
      footer {
        position: relative;
        text-align: left;
      }

      ul {

        & li {
          margin-bottom: 30px;
        }
      }

      & .heading-l {
        width: 70%;
        display: flex;
        flex-wrap: wrap;
        font-size: 4em;
        margin-bottom: 40px;
      }
    }

    &__heading {
      margin-bottom: 50px;

      & .logo-container {
        margin-right: 20px;

        & img {
          width: 40px;
        }
      }


      & span {
        font-size: 1.5em;
      }

      & .line {
        display: none;
      }
    }
  }

  .menu-btn {
    display: none;
  }

  .menu-btn-mobile {
    visibility: visible;
  }
}

@media screen and(max-width: 800px){
  .menu {
    &__nav {
      & .heading-l {
        width: 100%;
      }
    }

  }

}

@media screen and(max-width: 500px){
  .menu {
    &__heading {
      svg {
        min-width: 40px;
        width: 40px;
        margin-right: 10px;

      }

      & span {
        font-size: 1.2em;
      }
    }
  }
}