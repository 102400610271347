.section-10 {
  height: 250px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  background-color: #ffffff;

  h1 {
    letter-spacing: -5px;
    font-size: 8em;
    display: flex;

    & span {
      display: block;
      color: $red;
    }

    & .math {
      color: #000
    }
  }

  h4 {
    padding-left: 1em;
    color: $grey;
  }

  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1300px;
    width: 100%;
    padding: 2em;
  }

}

@media screen and(max-width: 1400px){

  .section-10 {

    h4 {
      padding-left: 0;
    }

    &__inner {
      flex-direction: column;
      text-align: center;
      padding: 4em 2em 2em;
    }
  }
}

@media screen and(max-width: 970px){
  .section-10 {
    h1 {
      font-size: 4em;
    }

    h4 {
      font-size: 1.2em;
    }
  }

}






