.principles {
  width: 100vw;
  height: 100vh;
  padding: 30px 30px 30px 10%;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: fixed;
  top: 0;


  &__inner {
    max-width: 1300px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
  }

  &__content {
    width: 30%;

    h3 {
      margin-bottom: 7px;
    }

    .subtitle {
      margin-bottom: 30px;
    }

    p {
      max-width: 440px;
      width: 100%;
    }
  }

  &__numcontainer {
    //border-left: 1px solid #fff;
    width: calc(10% - 30px);
    display: flex;
    padding-left: 30px;
    position: relative;

    &--line {
      position: absolute;
      left: 0;
      width: 1px;
      transition: .6s ease;
      transition-delay: .7s;
      height: 0;
      background-color: #fff;

      &.fill {
        height: 100%;
      }
    }


  }

  &__numbers {
    margin-right: 40px;
    list-style-type: none;
    padding: 20px 0 20px 0;
    display: flex;
    flex-direction: column;
    //justify-content: space-between;
    //overflow: hidden;


    li {

      color: $lightred;
      display: flex;
      justify-content: center;
      position: relative;
      width: 50px;
      transition: .3s;
      cursor: pointer;

      & .prin-number {
        font-size: 1.5em;
        padding-bottom: 1em;

        &.active {
          color: #fff
        }

        &:hover {
          color: #fff
        }
      }


      &:last-of-type {
        padding-bottom: 0;
      }

      &.active {
        color: #fff;
        & .principles__principle {
          opacity: 1;
        }
      }
    }
  }

  &__line {
    background-color: #fff;
    width: 0%;
    height: 1px;
    position: absolute;
    right: -10px;
    top: 25%;
    transform: translateX(100%);
    transition: .4s ease-in;

    &.active {
      width: 120%;
    }

  }

  &__principle {
    color: #ffffff;
    min-width: 570px;
    width: calc(55% - 100px);
    height: 100%;
    display: flex;
    flex-direction: column;


    h3 {
      line-height: 1.1;
      margin-bottom: 20px;
    }



  }
}
@media screen and(max-width: 1300px){
  .principles {
    padding: 2em 2em 0em;
    min-height: 100vh;
    height: 100%;
    &__inner {
      flex-direction: column;
    }

    &__numcontainer {
      width: 100%;
      padding-left: 0;

      &--line {
        left: 0;
        width: 0;
        height: 1px;

        &.fill {
          width: 100%;
          height: 1px;
        }
      }
    }

    &__numbers {
      flex-direction: row;
      justify-content: space-between;
      margin-right: 0;
      width: 100%;
      padding: 10px 0 0px 0;

      li {
        width: 40px;
      }
    }

    &__line {
      height: 0%;
      width: 1px;
      top: 65%;
      right: unset;
      left: 50%;
      transform: translateX(-50%);

      &.active {
        height: 15px;
        width: 1px;
      }
    }

    &__principle {
      position: relative;
      min-width: 100%;
      width: 100%;
      margin-left: 0;
      margin-top: 0px;
      text-align: left;

      & p {
        line-height: 1.2;
      }

      & h3 {
        margin-bottom: 5px;
      }
    }

    &__content {
      width: 100%;
      margin-bottom: 5px;


      & .subtitle {
        margin-bottom: 5px;
      }

      p {
        max-width: 100%;
      }

      h3 {
        margin-bottom: 4px;
      }
    }
  }
}


@media screen and(max-width: 1300px){
  .principles {

    &__principle {
      & h3 {
        font-size: 1.5em;
      }
    }

  }

}

