
.bottom-footer {
  position: fixed;
  z-index: -1;
  width: 100vw;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 55vh;

  footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 1em;

    svg {
      max-width: 180px;
      width: 100%;
      cursor: pointer;
    }

    & .linkedin-icon {
      margin: 15px 0 10px;
    }

    p {
      font-size: 14px;
      color: $grey;
    }
  }
}

.anchor {
  position: absolute;
  top: 0;

}

@media screen and(max-width: 750px){
  .bottom-footer footer svg {
    max-width: 120px;
  }

}