@media screen and(max-width: 970px){

  .sections-overflow-elements .arrow {
    left: -40px
  }

  .sections-overflow-elements .man img:nth-of-type(1) {
    opacity: .5 !important;
  }

  .sections-overflow-elements .man img:nth-of-type(2) {
    opacity: .5 !important;
    display: none;
  }

  .sections-overflow-elements .man img:nth-of-type(3) {
    opacity: .5 !important;
    display: none;
  }

}