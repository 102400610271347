.section-2 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 12% ;
  overflow: hidden;
  position: fixed;
  top: 0;

  & h2 {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    color: #fff;
    line-height: 1;

    & div {
      &:nth-of-type(2) {
        margin-left: 45px;
      }
      &:nth-of-type(3) {
        margin-left: 143px;
      }
    }

    & .counter {
      display: flex;
      position: relative;
      overflow: hidden;
      width: 600px;
    }


    & span {
      display: flex;

      ul {
        position: absolute;
        left: 150px;
        top: 126px;
        transition: 1s ease-in-out;
        list-style-type: none;
        padding-left: 0;

      }

    }
  }


  & .curves {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;

    & .bigcurve {
      position: absolute;
      top: -275px;
      right: -165px;
      width: 79%;
    }

    & .small-line {
      position: absolute;
      width: 200px;
      right: 400px;
      top: -100px;
    }

    & .line {
      position: absolute;
      bottom: -72px;
      left: -130px;
      width: 450px;
    }

  }
}

@media screen and(max-width: 1000px){
  .section-2 {
    & .curves {
      & .bigcurve {
        position: absolute;
        top: -175px;
        right: -145px;
        width: 82%;
      }
    }
  }
}
@media screen and(max-width: 950px) {
  .section-2 {
    & .curves {
      & .line {
        position: absolute;
        bottom: -58px;
        left: -130px;
        width: 350px;
      }

    }
  }
}




@media screen and(max-width: 800px){
  .section-2 {
    & .curves {
      & .bigcurve {
        position: absolute;
        top: -175px;
        right: -145px;
        width: 100%;
      }

      & .line {
        position: absolute;
        bottom: -72px;
        left: -130px;
        width: 300px;
      }

    }

    h2 {
      & span {
        display: flex;

        ul {
          position: absolute;
          left: 150px;
          top: 86px;
          transition: 1s ease-in-out;
          list-style-type: none;
          padding-left: 0;

        }

      }
    }
  }
}


@media screen and(max-width: 500px){
  .section-2 {

    h2 {
      justify-content: center;

      & div:nth-of-type(2) {
        margin-left: 0;
      }
      & div:nth-of-type(3) {
        margin-left: 0;
      }

    }
    & .curves {
      & .bigcurve {
        position: absolute;
        top: -370px;
        right: -255px;
        width: 170%;
      }

      & .line {
        position: absolute;
        bottom: -72px;
        left: -50px;
        width: 250px;
      }
    }
  }
}