.section-5  {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 12% ;
  position: fixed;
  top: 0;
  //background-color: white;

  h3 {
    max-width: 1000px;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 5;

    & span {

    }
  }

  & .man {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    //bottom: -5px;
    overflow: hidden;


    img {
      position: absolute;
      &:nth-of-type(1) {
        z-index: 3;
        right: -460px;
        bottom: 0;
        max-width: 1240px;
        width: 100%;
      }

      &:nth-of-type(2) {
        z-index: 2;
        right: 205px;
        bottom: 0;
        max-width: 830px;
        width: 100%;
        opacity: 0.7;
      }

      &:nth-of-type(3) {
        z-index: 1;
        opacity: 0.5;
        right: 690px;
        bottom: 0;
        max-width: 650px;
        width: 100%;
      }

      &:nth-of-type(4) {
        z-index: 1;
        opacity: 0.4;
        right: 1150px;
        bottom: 0;
        max-width: 380px;
        width: 100%;
      }

    }
  }

  & .curves {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;

    & .arrow {
      position: absolute;
      transform-origin: center;
      left: 45%;
      top: 50%;
      transform: translateY(-50%);
      height: 150%;
    }


  }
}

@media screen and(max-width: 1200px){

  .section-5 {
    & .curves {
      & .arrow {

        display: none;
      }
    }
  }
}

@media screen and(max-width: 970px){
  .section-5 {
    padding-left: 2em;

    h3 {
      max-width: 90%;
    }



    & .man {
      & img {

        &:nth-of-type(2) {
          right: 130px;
          max-width: 630px;
          width: 100%;

        }

        &:nth-of-type(3) {
          right: 500px;
          max-width: 450px;
        }

        &:nth-of-type(4) {
          display: none;
        }
      }
    }
  }
}

@media screen and(max-width: 970px){
  .section-5 {
    & .man {
      & img {
        &:nth-of-type(1) {
          right: unset;
          left: 250px;
          max-width: 530px;
          width: 100%;

        }


        &:nth-of-type(2) {
          right: unset;
          left: 100px;
          max-width: 430px;
          width: 100%;

        }

        &:nth-of-type(3) {
          right: unset;
          left: -20px;
          max-width: 350px;
        }


      }
    }
  }
}

@media screen and(max-width: 500px){
  .section-5 {
    h3 {
      max-width: 75%;
    }
  }
}


