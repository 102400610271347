.section-11 {
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  &.active-section {
    background-color: $blue;
  }
  //padding-right: 9em ;

  & .desk {
    display: flex;
  }

  & .mobile {
    display: none;
  }

  &__inner {
    max-width: 1300px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    z-index: 5;


  }

  &__content {
    max-width: 680px;
    h3 {
      display: flex;
      flex-direction: column;
      color: #1a292e;
      margin-bottom: 10px;

      span {

      }
    }

    h5 {
      color: #1a292e;
    }
  }

  & .man {
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    //bottom: -5px;
    overflow: hidden;


    img {
      position: absolute;
      &:nth-of-type(1) {
        z-index: 3;
        left: -415px;
        bottom: 0;
        max-width: 1250px;
        width: 100%;
        opacity: 0.5;
      }

      &:nth-of-type(2) {
        z-index: 2;
        left: 350px;
        bottom: 0;
        max-width: 550px;
        width: 100%;
        opacity: 0.5;
      }
    }
  }

  &__contact {
    margin-top: 80px;
    display: flex;
    overflow: hidden;
    a {
      display: block;
      font-size: 1.2em;
      font-weight: bold;
      color: #1a292e;
      text-decoration: none;

      &:nth-of-type(1) {
        margin-right: 65px;
      }

    }
  }
}

@media screen and(max-width: 1100px){
  .section-11 {
    & .man {
      img {
        &:nth-of-type(1) {
          left: -355px;

        }
        &:nth-of-type(2) {
          left: 300px;
          max-width: 450px;
        }
      }
    }
  }
}

@media screen and(max-width: 970px){

  .section-11 {
    &__inner {
      padding: 2em;
    }

    & .man {
      img {
        &:nth-of-type(1) {
          left: -315px;
          max-width: 650px;
        }
        &:nth-of-type(2) {
          left: 100px;
          max-width: 450px;
        }
      }
    }

    & .desk {
      display: none;
    }

    & .mobile {
      display: block;
    }

  }
}

@media screen and(max-width: 830px){
  .section-11 {

    & .man {
      img {
        &:nth-of-type(1) {
          right: -215px;
          left: unset;
          max-width: 550px;

        }
        &:nth-of-type(2) {
          right: 100px;
          left: unset;
          max-width: 400px;
        }
      }
    }
  }
}

@media screen and(max-width: 550px){
  .section-11 {

    &__contact {
      flex-direction: column;

      & a {
        &:nth-of-type(2) {
          margin-top: 10px;
        }
      }
    }

    & .man {
      img {
        &:nth-of-type(1) {
          right: -185px;
          left: unset;
          max-width: 400px;

        }
        &:nth-of-type(2) {
          right: 80px;
          left: unset;
          max-width: 250px;
          opacity: .2;
        }
      }
    }
  }
}






