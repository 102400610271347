.people {
  color: #fff;
  //padding-left: 11em;
  overflow: hidden;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;

  h3 {
    color: #fff;
    margin-bottom: 20px;
  }

  &__inner {
    display: flex;
    align-items: center;
    position: relative;
    z-index: 2;
    height: 100%;
    max-width: 1421px;
    width: 100%;
  }

  &__content {
    width: 30%;
    //margin-top: 8em;

    p {
      font-weight: 900;
      font-size: 24px;
      line-height: 1.4;
    }
  }

  &__select {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
    margin-top: 80px;
    max-width: 426px;
    width: 100%;
    overflow: hidden;

  }

  &__image {
    width: 99px;
    height: 99px;
    background-color: #223237;
    overflow: hidden;
    cursor: pointer;
    border: 1px solid transparent;
    transition: .4s;

    &:hover {
      border: 1px solid red;
    }

    &.active {
      border: 1px solid red;
    }

    img {
      max-width: 100%;
      width: 100%;
    }
  }

  &__art-container {
    margin-left: 50px;
    width: 70%;
    height: 100%;
    position: relative;


    &.flex-bottom {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      //padding-right: 12em;
      padding-bottom: 4em;
    }

    img {
      width: auto;
      height: 100%;
    }
  }

  &__info {
    text-align: right;
    max-width: 375px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    h4 {
      margin-bottom: .5em;
    }

    p {
      margin-bottom: 2em;
      white-space: pre-wrap;
    }
  }

  &__background {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    height: 100%;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;

    & img {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      height: 100%;
      object-fit: contain;
      object-position: bottom;
    }

    & .person-curve {
      position: absolute;
      right: 50%;
      transform: translateX(75%);
      bottom: -30%;
      z-index: -1;
      height: 100%;
    }
  }

  &__gradient {
    position: fixed;
    bottom: 0;
    height: 50vh;
    width: 100%;
    background: rgb(0,0,0);
    background: linear-gradient(0deg, rgba(0,0,0,0.7651435574229692) 0%, rgba(0,0,0,0) 100%);
  }

  & .curves {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;

    & .arrow {
      position: absolute;
      transform-origin: center;
      left: 24%;
      top: 50%;
      transform: translateY(-50%);
      height: 140%;
    }

    & .curve-top {
      position: absolute;
      right: -60px;
      top: -60px;
      height: 40%;
    }

    & .curve-bottom {
      position: absolute;
      right: -60px;
      bottom: -60px;
      height: 40%;
    }
  }

}

.popupContent {
  z-index: 120;
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: $green;
  padding: 6em 2em 1em;
  white-space: pre-wrap;

  h4 {
    margin-bottom: 20px;
  }


}

.popup {
  &__inner {
    position: relative;
    & .btn-close {
      position: absolute;
      right: 0;
      top: 0;
      transform: translateY(15px);
      display: block;
      height: 25px;
      width: 25px;
      color: $grey;
      cursor: pointer;

      &:hover {

        &:after {
          transform: rotate(360deg);
        }
        &:before {
          transform: rotate(360deg);
        }
      }

      &:after {
        content: '';
        position: absolute;
        right: 0;
        width: 25px;
        height: 1px;

        transform: rotate(45deg) translateY(-50%);
        transform-origin: center;
        background-color: $grey;
        transition: 1s ease;

      }

      &:before {
        content: '';
        position: absolute;
        //top: 50%;
        right: 0;
        width: 25px;
        height: 1px;
        transform: rotate(-45deg) translateY(-50%);
        background-color: $grey;
        transition: 1s ease;
      }

    }
  }
}


@media screen and(max-width: 1350px){
  .people {

    &__inner {
      padding: 6em 2em 0 2em ;
    }

    &__content {
      width: 40% ;
    }

    &__art-container {
      width: 60%;
      margin-left: 40px;


    }

    &__background {
      & img {
        // max-width: 750px;
      }

      & .person-curve {
        height: 60%;
      }
    }

    & .curves {
      & .arrow {
        position: absolute;
        transform-origin: center;
        left: 21%;
        top: 50%;
        transform: translateY(-50%);
        height: 140%;
      }
    }
  }
}

@media screen and(max-width: 1140px){
  .people {
    &__content {
      width: 50%;
    }
  }
}

@media screen and(max-width: 1100px){

  .people {
    &__background {
      & img {
        // max-width: 700px;
      }
    }
  }
}


@media screen and(max-width: 970px){
 .people {

   h3 {
     margin-bottom: 10px;
   }

   &__content {
     width: 100%;
   }

   &__select {
     max-width: 430px;

     grid-template-columns: repeat(4, 1fr);
     margin-top: 10px;
   }

   &__image {
     width: 100px;
     height: 100px;
   }

   &__inner {
     flex-direction: column;
     justify-content: space-between;
     padding: 5em 2em 0 2em;
   }

   &__info {
     margin-top: -15px;
     p {
       margin-bottom: 1em;
     }
   }
   &__background {
     & img {
      left: 0;
       transform: translateX(-30%);
     }

     & .person-curve {

       height: 55%;
     }

   }

   &__art-container {
     margin-left: 0;
     width: 100%;
     height: unset;
     margin-top: 15px;

     & img {
       display: block;
       margin: 0 auto;
       max-height: 290px;
       height: 100%;
     }
   }

   & .curves {
     & .arrow {
       position: absolute;
       transform-origin: center;
       left: 0%;
       top: 50%;
       transform: translateY(-50%) translateX(-60%);
       height: 70%;
     }

     & .curve-top {
       position: absolute;
       right: -20px;
       top: -20px;
       height: 20%;
     }

     & .curve-bottom {
       position: absolute;
       right: -20px;
       bottom: -20px;
       height: 20%;
     }
   }
 }

}


@media screen and(max-width: 800px){
  .people {
    &__background {
      & img {
        max-height: 50%;
      }

      & .person-curve {

        bottom: -10%;
      }

    }

    &__select {
      max-width: 390px;
      grid-template-columns: repeat(5, 1fr);
      gap: 5px;
    }

    &__image {
      width: 70px;
      height: 70px;
    }
    &__info p {
      padding-left: 5em;
      line-height: 1.1;
      font-size: 14px;

      span {
        margin-top: 10px;
        display: block;
        cursor: pointer;
        color: $red;
      }
    }

  }
}

@media screen and(max-width: 650px){
  .people {
    &__select {
      max-width: 470px;

     grid-template-columns: repeat(6, 1fr);
    }

    &__image {
      width: 70px;
      height: 70px;
    }  
  }
}


@media screen and(max-width: 550px){

  .people {

    &__select {
      max-width: 350px;
      grid-template-columns: repeat(6, 1fr);
    }

    &__image {
      width: 50px;
      height: 50px;
    }
  

    &__background {
      & img {
        transform: translateX(-33%);
      }

      & .person-curve {
        right: -20%;
        height: 45%;
        bottom: -10%;
      }
    }
  }
}


@media screen and(max-width: 500px){
  .people {

   

    &__content {
      width: 100%;

      p {
        width: 90%;
        font-size: 1em;
      }
    }
  }
}

@media screen and(max-width: 400px){
  .people {

    &__select {
      max-width: 390px;
    }

    &__image {
      width: 50px;
      height: 50px;
    }

    &__content {
      width: 100%;

    }
  }
}