.section-3 {
  display: flex;
  justify-content: flex-end;
  padding-right: 12em ;
  padding-bottom: 9em;
  position: fixed;
  top: 0;

  h2 {
    color: $lightgreen;
    display: flex;
    flex-direction: column;
    align-self: flex-end;
    line-height: 1;

    div {
      overflow: hidden;
    }


    span {
      display: block;
    }
  }

  & .curves {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;

    & .bigcurve {
      position: absolute;
      bottom: -285px;
      right: -150px;
      width: 79%;
    }

    & .line {
      position: absolute;
      top: -72px;
      left: -130px;
      width: 450px;
    }

  }
}

@media screen and(max-width: 1500px){
  .section-3 {
    .curves {
      .bigcurve {
        width: 89%;
      }
    }
  }
}


@media screen and(max-width: 1280px){
  .section-3 {
    .curves {
      .bigcurve {
        width: 95%;
      }
    }
  }
}

@media screen and(max-width: 1200px){
  .section-3 {
    .curves {
      .bigcurve {
        width: 105%;
      }
    }
  }
}

@media screen and(max-width: 1050px){
  .section-3 {
    .curves {
      .bigcurve {
        width: 115%;
      }
    }
  }
}

@media screen and(max-width: 1000px){
  .section-3 {
    .curves {
      .bigcurve {
        width: 125%;
      }
    }
  }
}

@media screen and(max-width: 920px){
  .section-3 {
    .curves {
      .bigcurve {
        width: 130%;
      }
    }
  }
}

@media screen and(max-width: 870px){
  .section-3 {
    padding-bottom: 5em;
    .curves {
      .bigcurve {
        width: 140%;
      }
    }
  }
}


@media screen and(max-width: 680px){
  .section-3 {
    padding-right: 2em;
    .curves {
      .bigcurve {
        width: 140%;
        bottom: -215px;
        left: -150px;
      }
    }
  }
}


@media screen and(max-width: 600px){
  .section-3 {
    .curves {
      .bigcurve {
        width: 200%;
        bottom: -170px;
        left: -220px;
      }

      .line {
        position: absolute;
        top: -72px;
        left: -50px;
        width: 250px;
      }
    }
  }
}


